import { Button } from "react-bootstrap";

interface NavBarLoggedOutViewProps {
    onSignUpCliccked: () => void
    onLogInCliccked: () => void
}

const NavBarLoggedOutView = ({ onSignUpCliccked, onLogInCliccked }: NavBarLoggedOutViewProps) => {
    return ( 
        <>
        <Button onClick={onSignUpCliccked}>Sign Up</Button>
        <Button onClick={onLogInCliccked}>Log In</Button>
        </>
     );
}

export default NavBarLoggedOutView;