const PrivacyPage = () => {
    return (
        <div>
            <p>
                We care about your privacy.
            </p>
        </div>
    );
}

export default PrivacyPage;